<template>
    <modal ref="modalCambiarLogo" titulo="Cambiar Logo" tamano="modal-lg" @guardar="postLogo()">
        <div v-show="parametros.tipo == 16">
            <slim-cropper ref="crop_logo1" :options="slimOptions1" class="border cr-pointer bg-white mx-lg-auto" style="height:162px;width:162px;border-radius:12px;" />
        </div>
        <div v-show="parametros.tipo == 14 || parametros.tipo == 15">
            <slim-cropper ref="crop_logo2" :options="slimOptions2" class="border cr-pointer bg-white mx-lg-auto" style="height:162px;width:162px;border-radius:12px;" />
        </div>
    </modal>
</template>

<script>
import Service from "~/services/configurar/admin/personalizacion"
export default {
    props: {
        parametros: {
            type: Object,
            default: () => { return {}}
        },
    },
    data(){
        let vm = this
        return {
            slimOptions1: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
            },
            slimOptions2: {
                ratio: '16:9',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
            }
        }
    },
    methods: {
        toggle(){
            this.dataLogos()
            this.$refs.modalCambiarLogo.toggle();
        },
        async dataLogos(){
            try {
                console.log(this.parametros.tipo);

                if(this.parametros.logo != null){
                    switch (this.parametros.tipo){
                    case 14:
                        return this.$refs.crop_logo2.set_image(this.parametros.logo);
                    case 15:
                        return this.$refs.crop_logo2.set_image(this.parametros.logo);        
                    case 16:
                        return this.$refs.crop_logo1.set_image(this.parametros.logo);
                    }
                }
                this.limpiarCrop();
            } catch(e){
                this.error_catch(e);
            }
        },
        limpiarCrop(){
            this.$refs.crop_logo1.instanciaCrop.remove();
            this.$refs.crop_logo2.instanciaCrop.remove();
        },
        async postLogo(){
            try {
                let textObj = this.parametros.tipo == 16 ? this.$refs.crop_logo1.get_image() : this.$refs.crop_logo2.get_image();
                let model = {
                    tipo: this.parametros.tipo,
                    dimension: this.parametros.dimension,
                    upload: this.parametros.upload,
                    texto: textObj,
                };
                if(model.texto == null) return this.notificacion('Mensaje', 'Para continuar, por favor seleccione una imagen.','warning');
                const {data} = await Service.postLogoSistema(model);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$emit('actualizar');
                this.$refs.modalCambiarLogo.toggle();
            } catch(error){
                this.error_catch(error)
            }
        },
    }
}
</script>
